import React from "react"
import "./styles.scss"

const SectionContainer = ({ children, centered, className }) => {
  return (
    <section className="section">
      <div
        className={`section__inner-container ${className && className} ${
          centered && "section__inner-container--centered"
        }`}
      >
        {children}
      </div>
    </section>
  )
}

export default SectionContainer
