import { useState, useEffect } from 'react'
import { createClient } from 'contentful'

const useContentfulPosts = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const contentfulClient = createClient({
      space: process.env.CONTENTFUL_CLIENT_SPACE,
      environment: process.env.CONTENTFUL_CLIENT_ENV,
      accessToken: process.env.CONTENTFUL_CLIENT_ACCESS_TOKEN,
    })

    contentfulClient
      .getEntries({ content_type: 'blogPost' })
      .then(({ items: contentfulPosts }) => {
        const posts = contentfulPosts.map((doc) => {
          const authors = doc.fields.authors
            ? doc.fields.authors.map((author) => author.fields)
            : []

          return {
            authors,
            id: doc.fields.path,
            date: doc.fields.date,
            path: doc.fields.path,
            title: doc.fields.title,
            category: doc.fields.category,
            url: `/blog/posts/${doc.fields.path}`,
            post_content: doc.fields.post_content,
            icon: doc.fields.banner.fields.file.url,
            shortDescription: doc.fields.short_description,
          }
        })

        setPosts(posts)
      })
      .catch((err) => err)
  }, [])

  return { posts }
}

export default useContentfulPosts
