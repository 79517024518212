import { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export default function useStaticImage() {
  const [images, setImages] = useState([])
  const data = useStaticQuery(graphql`
    query StaticImage {
      allImageSharp {
        edges {
          node {
            gatsbyImageData
          }
        }
      }
    }
  `)

  useEffect(() => {
    setImages(() => {
      return unwrapImageData(data)
    })
  }, [])

  function unwrapImageData(data) {
    return data.allImageSharp.edges.map((img) => {
      return img.node.gatsbyImageData
    })
  }

  function getImage(imageName) {
    const foundImage = images.filter((value) => {
      return value.images.fallback.src.split('/').pop() === imageName
    })[0]

    if (!foundImage) {
      return {
        images: {
          fallback: {
            src: '',
          },
        },
      }
    }

    return foundImage
  }

  function getMultipleImages(imageNameArray) {
    return imageNameArray.map((imageName) => {
      return getImage(imageName)
    })
  }

  return [getImage, getMultipleImages]
}
