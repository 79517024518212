import React from 'react'
import Layout from '../../templates/layout'
import Seo from '../../helpers/seo'
import Slogan from '../../components/molecules/Slogan'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../../components/molecules/Button'
import Articles from '../../components/organisms/Articles'

import './styles.scss'
import SectionContainer from '../../components/molecules/SectionContainer'
import { isMobile } from 'react-device-detect'
import NewsLetter from '../../components/organisms/NewsLetter'

const Banner = () => {
  return (
    <section className="banner-thanks">
      {isMobile ? (
        <StaticImage
          src="../../assets/images/banner-404.webp"
          alt="DR_"
          placeholder="blurred"
          loading="eager"
          width={920}
          objectFit="cover"
          layout="fixed"
          objectPosition="6% center"
          quality={95}
          formats={['auto', 'webp', 'avif']}
        />
      ) : (
        <StaticImage
          src="../../assets/images/banner-404.webp"
          alt="DR_"
          placeholder="blurred"
          loading="eager"
          width={1920}
          objectFit="cover"
          layout="fixed"
          objectPosition="6% center"
          quality={95}
          formats={['auto', 'webp', 'avif']}
        />
      )}
    </section>
  )
}

const Content = () => {
  return (
    <SectionContainer>
      <article className="thanks">
        <p className="thanks__paragraph">
          Agradecemos a sua mensagem! Em breve nosso time entrará em contato.
        </p>

        <Button
          text="Voltar ao início"
          classButton="primary"
          fontColor="orange-dark"
          link="/"
        />
      </article>
    </SectionContainer>
  )
}

const Thanks = () => {
  return (
    <Layout page="Thanks">
      <Banner />
      <Slogan>
        <strong>Obrigado!</strong>
      </Slogan>
      <Content />
      <Articles />
      <NewsLetter />
    </Layout>
  )
}

export const Head = () => <Seo title="Thanks" />

export default Thanks
