import React from 'react'
import SectionContainer from '../SectionContainer'
import './styles.scss'

const Slogan = ({ children, id }) => {
  return (
    <SectionContainer>
      <h1 id={id} className="slogan">
        {children}
      </h1>
    </SectionContainer>
  )
}

export default Slogan
