import React from 'react'
import './styles.scss'
import { dateToLocaleString } from '../../../helpers/dateToLocaleString'
import useContentfulPosts from '../../../hooks/useContentfulPosts'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { formatSrcToGatsbyImage } from '../../../helpers/formatSrcToGatsbyImage'

const Articles = () => {
  const { posts } = useContentfulPosts()

  const featuredPosts = posts
    .map((p) => {
      const [day, month, year] = p.date.split('/')
      const stringDate = `${month}/${day}/${year}`
      const timestamp = new Date(stringDate)

      return { ...p, date: timestamp }
    })
    .sort((a, b) => b.date - a.date)

  return (
    <section className="articles">
      <div className="articles__container">
        <div className="articles__title">
          <h2>Confira nosso conteúdo</h2>
        </div>
        <div className="articles__news">
          <ul>
            {posts &&
              featuredPosts
                .slice(0, 3)
                .map(({ category, date, path, shortDescription, title, icon }) => {
                  let categoryCss = category.toLowerCase()
                  if (category.includes(' ')) {
                    categoryCss = categoryCss.substring(0, category.indexOf(' '))
                  }
                  const linkTo = `/blog/posts/${path}`
                  return (
                    <li key={path}>
                      <Link to={linkTo}>
                        <GatsbyImage
                          className="articles__news__image"
                          image={formatSrcToGatsbyImage(icon)}
                          alt={title}
                        />
                      </Link>

                      <p>{dateToLocaleString(date)}</p>
                      <p>
                        <span
                          className={`articles__news__category articles__news__category-${categoryCss}`}
                        >
                          {category.toUpperCase()}_
                        </span>
                      </p>
                      <p>
                        <Link to={linkTo}>{title}</Link>
                      </p>
                      <p>{shortDescription}...</p>
                      <p>
                        <Link to={linkTo}>
                          Ler mais
                        </Link>
                      </p>
                    </li>
                  )
                })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Articles
