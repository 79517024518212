import React, { useState } from 'react'
import './styles.scss'
import useStaticImage from '../../../hooks/useStaticImage'
import { GatsbyImage } from 'gatsby-plugin-image'
import Button from '../../molecules/Button'
import { EMAIL_CONTACT_SOCIAL_MEDIA } from '../../../helpers/constants'

const NewsLetter = () => {
  const [getImage] = useStaticImage()
  const [successMessage, setSuccessMessage] = useState({
    success: false,
    error: false,
    loading: false,
  })

  const onSubmit = async (e) => {
    e.preventDefault()
    setSuccessMessage((prevState) => ({ ...prevState, loading: true }))
    const formValues = Object.fromEntries(new FormData(e.target))
    const url = 'https://vx3nuvayzb.execute-api.us-east-1.amazonaws.com/prod/contact'
    const payload = {
      ...formValues,
      name: 'Newsletter',
      to: EMAIL_CONTACT_SOCIAL_MEDIA,
      subject: `Solicitação Newsletter, DR_`,
    }

    await fetch(url, { method: 'POST', body: JSON.stringify(payload) })
      .then(() => {
        setSuccessMessage((prevState) => ({ ...prevState, success: true, error: false }))
      })
      .catch(() => {
        setSuccessMessage((prevState) => ({ ...prevState, error: true, success: false }))
      })
      .finally(() => {
        setSuccessMessage((prevState) => ({ ...prevState, loading: false }))
      })
  }

  return (
    <section className="subscribe-component">
      <div className="subscribe-component__container">
        <div className="subscribe-component__container-wrap">
          <div className="subscribe-component__container-wrap-text">
            <p>Inscreva-se e receba as novidades do conteúdo da DR_</p>
          </div>
          <form
            className="subscribe-component__container-wrap-inputs"
            onSubmit={onSubmit}
          >
            <input required type="email" name="email" placeholder="Digite seu e-mail" />
            <Button
              classButton="primary"
              text="Enviar"
              fontColor="orange-light"
              type="submit"
            />
          </form>
          {successMessage.success && !successMessage.loading && (
            <p className="subscribe-component__container-wrap-text--success">
              Agradecemos a sua inscrição! Conteúdos de qualidade esperam por você.
            </p>
          )}
          {successMessage.error && !successMessage.loading && (
            <p className="subscribe-component__container-wrap-text--error">
              Ocorreu um erro ao cadastrar o e-mail, tente novamente mais tarde.
            </p>
          )}
          {successMessage.loading && (
            <p className="subscribe-component__container-wrap-text--loading">
              Aguarde...
            </p>
          )}
        </div>
        <div className="subscribe-component__container-img">
          <GatsbyImage image={getImage('vetor.webp')} alt="Newsletter image" />
        </div>
      </div>
    </section>
  )
}

export default NewsLetter
